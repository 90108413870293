<template>
  <section class="wrappage">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h1 class="title-section text-uppercase" data-aos="fade-left">{{$t('contacts.lead1')}}</h1>
          <p class="text-paragraph" v-html="$t('contacts.textlead1')"></p>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-white">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h1 class="title-section mb-5 text-uppercase" data-aos="fade-left">{{$t('contacts.lead2')}}</h1>
          <div class="row">
            <div class="col-lg-4 mb-5">
              <h5 class="titledescription">Riccardo Cicerone</h5>
              <div class="subtitle">Chief Operative Officer</div>
              <p class="text">
                riccardo@saturnoconsulting.com<br>
                <a href="tel:+393661470797">+39 366 1470797</a>
              </p>
            </div>
            <div class="col-lg-4 mb-5">
              <h5 class="titledescription">Renzo Cappelli</h5>
              <div class="subtitle">Chief Technical Officer</div>
              <p class="text">
                renzo@saturnoconsulting.com<br>
                <a href="tel:+393518924196">+39 351 8924196</a>
              </p>
            </div>
            <div class="col-lg-4 mb-5">
              <h5 class="titledescription">Angelo Priori</h5>
              <div class="subtitle">Chemical Engineering</div>
              <p class="text">
                angelo@saturnoconsulting.com<br>
                <a href="tel:+393357617803">+39 335 7617803</a>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 mb-5">
              <h5 class="titledescription fw-bolder">Antonello Dulcamara</h5>
              <div class="subtitle">Head of Sales</div>
              <p class="text">
                antonello@saturnoconsulting.com<br>
                <a href="tel:+393478313815">+39 347 8313815</a>
              </p>
            </div>
            <div class="col-lg-4 mb-5">
              <h5 class="titledescription fw-bolder">Annamaria Colagrande</h5>
              <div class="subtitle">Web Developer</div>
              <p class="text">
                annamaria@saturnoconsulting.com<br>
                <a href="tel:+393518924196">+39 351 8924196</a>
              </p>
            </div>
            <div class="col-lg-4 mb-5">
              <h5 class="titledescription fw-bolder">Alessandra Berardi</h5>
              <div class="subtitle">Operation manager</div>
              <p class="text">
                alessandra@saturnoconsulting.com<br>
                <a href="tel:+393518924196">+39 351 8924196</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-black">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <h4 class="text-grey mb-4" v-html="$t('contacts.titlescroll')"></h4>
          <TextScroller :class="'casa1'" :text="$t('contacts.textscroll')"/>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-white">
    <div class="container">
      <FormContact/>
    </div>
  </section>
</template>
<script>
import FormContact from "../components/FormContact";
import TextScroller from "@/components/TextScroller.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Contact',
  data() {
    return {}
  },
  components: {TextScroller, FormContact},
  mounted() {

  }
}
</script>

<style scoped>

</style>