<template>
  <div class="header">
    <nav class="navbar" id="navbar" v-bind:class="{'navbar-bg' : (this.classMenu || routeName !== 'Home')}">
      <div class="container-fluid">
        <router-link class="navbar-brand" to="/">
          <img class="logo" src="/img/logo.png">
        </router-link>
        <button class="button-menu" v-on:click="toggleMenu()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/>
          </svg>
        </button>
      </div>
    </nav>
  </div>
  <aside class="drawer" v-bind:class="{'is-open' : mobileMenu}">
    <div class="close">
      <button class="button-exit" v-on:click="closeMenu">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
        </svg>
      </button>
    </div>
    <ul class="drawer-menu">
      <li class="menu-item" v-bind:class="{'current-menu-item' : this.$route.name === 'Home'}">
        <router-link to="/" class="link" v-on:click="changeMenu('home')">{{ $t("menu.home") }}</router-link>
      </li>
      <!--<li class="menu-item" v-bind:class="{'current-menu-item' : this.$route.name === 'Products'}">
        <router-link to="prodotti" class="link" v-on:click="changeMenu('software')">Prodotti software</router-link>
      </li>-->
      <li class="menu-item" v-bind:class="{'current-menu-item' : this.$route.name === 'Casa Saturno'}">
        <router-link to="casasaturno" class="link" v-on:click="changeMenu('casasaturno')">{{ $t("menu.casasaturno") }}</router-link>
      </li>
      <li class="menu-item" v-bind:class="{'current-menu-item' : this.$route.name === 'Digital'}">
        <router-link to="digital" class="link" v-on:click="changeMenu('digital')">{{$t('menu.digital')}}</router-link>
      </li>
      <!--<li class="menu-item" v-bind:class="{'current-menu-item' : this.$route.name === 'Connettore'}">
        <router-link to="connettore" class="link" v-on:click="changeMenu('connettore')">WooConnector</router-link>
      </li>
      <li class="menu-item" v-bind:class="{'current-menu-item' : this.$route.name === 'DistintaBase'}">
        <router-link to="distintabase" class="link" v-on:click="changeMenu('distintabase')">Distinta base</router-link>
      </li>-->
      <li class="menu-item" v-bind:class="{'current-menu-item' : this.$route.name === 'Casi studio'}">
        <router-link to="casestudies" class="link" v-on:click="changeMenu('casasaturno')">{{ $t("menu.casestudies") }}</router-link>
      </li>
      <li class="nav-button menu-item" v-bind:class="{'current-menu-item' : this.$route.name === 'Contatti'}">
        <router-link to="contatti" class="link" v-on:click="changeMenu('contatti')">{{ $t("menu.contacts") }}</router-link>
      </li>
      <li>
        <select v-model="$i18n.locale" v-on:change="onChangeLang()" class="form-control language-selector">
          <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale" >
            <template v-if="locale === 'it'">🇮🇹 IT</template><template v-else-if="locale === 'en'">🇬🇧 EN</template>
          </option>
        </select>
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header',
  data() {
    return {
      mobileMenu: false,
      classMenu : false
    }
  },
  computed : {
    routeName (){
      return this.$route.name;
    }
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    onChangeLang(){
      this.mobileMenu = false;
      document.querySelector('html').setAttribute("lang", this.$i18n.locale);
    },
    handleScroll(){
      this.classMenu = window.scrollY > 50;
    },
    changeMenu(menu) {
      this.$store.commit('changeMenu', menu);
      this.mobileMenu = false;
    },
    closeMenu() {
      this.mobileMenu = false;
    },
    toggleMenu() {
      this.mobileMenu ? this.mobileMenu = false : this.mobileMenu = true;
    }
  }
}
</script>
<style scoped>
.header{
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 10000;
}

.navbar{
  background-color: transparent;
}

.logo{
  margin-left: 15px;
  width: 180px;
}

/*menu drawer*/

.drawer {
  font-family: 'Space Mono', monospace !important;
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10000;
  background-color: rgba(0,0,0,0.95);
  overflow: auto;
}

.drawer.is-open, .drawer-overlay.is-open{
  transition: 0.3s;
  display: block;
}

.drawer-menu{
  list-style: none;
  padding: 0;
  margin: 0;
}

.drawer-menu .menu-item {
  text-align: center;
}

.drawer-menu .menu-item:hover{
  color: #dedede;
}

.drawer-menu .menu-item a {
  font-size: 20px;
  display: block;
  width: 100%;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  margin: 30px 0;
}

.drawer a{
  color: #fff;
}

ul {
  padding: 0;
  margin: 0;
}
.drawer .close{
  width: 100%;
  padding: 20px;
}

.drawer .button-exit{
  margin: 0 auto;
  display: flex;
  align-items: center;
  background-color: #000;
  color: #ffffff;
  border: none;
}

.button-menu{
  width: 35px;
  height: 35px;
  border: none;
  margin-right: 20px;
  background-color: transparent;
}

.button-menu svg{
  width: 30px;
  height: 30px;
  fill: #fff;
}

.drawer .button-exit{
  border: none;
  background-color: transparent;
}

.drawer .button-exit svg{
  width: 40px;
  height: 40px;
  fill: #fff;
}

.navbar-bg {
  background-color: #000;
  transition: 0.5s;
}

.language-selector{
  width: auto;
  margin: 0 auto;
}
</style>
