<template>
  <Spinner/>
  <Header/>
  <router-view :key="$route.path"/>
  <Footer/>
  <ScrollTop/>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Spinner from '@/components/Spinner'
import ScrollTop from "@/components/ScrollTop";

export default {
  name: 'App',
  components: {
    ScrollTop,
    Spinner,
    Header,
    Footer
  }
}
</script>

<style>

.wrappage{
  margin-top: 93px;
  margin-bottom: 60px;
  padding-top: 80px;
}

/*wrapper without overflow*/
.wrappersticky{
  padding-top: 30px;
  width: 100%;
  background-color: #dedede;
}

.wrapper{
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 60px;
  width: 100%;
  background-color: #dedede;
}

.section-home{
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #002549;
  color: #fff;
  min-height: 550px;
}

.section-home video{
  width: 100%;
  float: left;
  position: relative;
}

.leading-home{
  text-align: left;
  width: 100%;
  position: absolute;
  bottom: 150px;
  padding: 0 150px 0 150px;
}

.leading-home h1{
  font-size: 70px;
}

.layer{
  float: left;
  z-index:9999;
  background-color: rgba(0,0,0,0.5);
  position: absolute;
  width: 100%;
  height: 100%;
}

.cardblock{
  overflow: hidden;
  padding: 50px;
  margin-bottom: 30px;
  background-color: #fff;
  border-radius: 15px !important;
}

.cardhome{
  margin-bottom: 30px;
  padding: 70px 50px 70px 50px;
  min-height: 300px;
  border-radius: 15px !important;
  overflow: hidden;
  border: none !important;
  background-color: #000 !important;
}

.cardhome h1{
  background: -webkit-linear-gradient(#fff, #6c54ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 70px;
  color: #fff;
}

.cardhome p{
  color: #fff;
  line-height: 40px;
  font-size: 30px;
}

.fl-block{
  margin-bottom: 30px;
  border-radius: 15px;
  width: 100%;
  background-color: #000;
  min-height: 200px;
}

.sticky{
  position: sticky;
  top: 120px;
  bottom: 150px;
}

.swiperwrapper{
  width: 100%;
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 60px;
}

.title-section{
  background: -webkit-linear-gradient(#1b1b42, #6c54ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 80px;
}

.titledescription{
  font-weight: bold;
  margin-bottom: 0;
}

.title{
  color: #000;
}

.title-data{
  font-size: 80px;
  text-align: center;
}

.title-data svg{
  margin-bottom: 20px;
  width: 130px;
}

.text-paragraph{
  font-size: 20px;
}

.text-grey{
  color: #7a7a7a;
}

.btn-slider{
  border-radius: 15px;
  border: none;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  background-color: #dedede !important;
}

.btn-slider:hover{
  background-color: #d9d9d4 !important;
}

.btn-slider.swiper-button-disabled{
  background-color: #dedede;
  border: none;
}

.btn-slider svg{
  width: 20px !important;
  height: 20px !important;
  margin-bottom: 3px;
}

.icon{
  width: 100px;
}

@media screen and (max-width: 576px) {

}

@media screen and (max-width: 768px) {

  .textabsolute{
    margin-top: 40px !important;
  }

  .section-home video{
    width: 260%
  }

  .leading-home h1{
    font-size: 40px;
  }

  .leading-home{
    padding: 30px;
    bottom: 40px;
  }

  .cardhome h1{
    font-size: 40px;
  }

  .title-section{
    font-size: 50px;
  }
}

.video{
  min-height: 350px;
}

.border-brand{
  border: 1px solid #000;
}

input,select, textarea{
  border-radius: 0 !important;
}

.subtitle{
  margin-bottom: 10px;
  font-style: italic;
}

@media screen and (max-width: 992px) {
  .wrappersticky{
    background-color: #000;
  }
}

@media screen and (max-width: 1200px) {

  .leading-home h1{
    font-size: 40px;
  }

  .cardhome{
    min-height: 300px;
  }
}
</style>
