<template>
  <section class="wrappage">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mb-3">
          <h1 class="title-section mb-5" data-aos="fade-left" v-html="$t('digital.lead')"></h1>
          <p class="text-paragraph" v-html="$t('digital.textlead')"></p>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-black">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <h4 class="text-grey mb-4" v-html="$t('digital.leadai')"></h4>
          <TextScroller :class="'digital1'" :text="$t('digital.textai')"/>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4">
          <div class="cardblock" style="height: 400px;">
            <h1 class="mb-5" data-aos="fade-down" v-html="$t('digital.title1')"></h1>
            <p class="text-paragraph" v-html="$t('digital.text1')"></p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="cardblock" style="height: 400px;">
            <h1 class="mb-5" data-aos="fade-down" v-html="$t('digital.title2')"></h1>
            <p class="text-paragraph" v-html="$t('digital.text2')"></p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="cardblock" style="height: 400px;">
              <h1 class="mb-5" data-aos="fade-down" v-html="$t('digital.title3')"></h1>
              <p class="text-paragraph" v-html="$t('digital.text3')"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-black">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <h4 class="text-grey mb-4">La nostra specialità</h4>
          <TextScroller :class="'digital2'" :text="'Siamo specializzati nella realizzazione di soluzioni software per la vendita in cloud, sia di tipologia SaaS e sia con prodotti personalizzati interfacciati con l\'hardware fiscale, in modo da gestire le vendite in modalità omnichannel.'"/>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-white">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <h1 class="title-section" data-aos="fade-left">I nostri partners</h1>
          <p class="text-paragraph">Per fare prodotti digitali innovativi è importante avere solidi partners.</p>
        </div>
        <div class="col-lg-4">
          <div class="cardblock">
            <div class="body">
              <div class="cardmedia">
              <img class="img-fluid" src="/img/partners/teamsystem.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="cardblock">
            <div class="body">
              <div class="cardmedia">
                <img class="img-fluid" src="/img/partners/cassaincloud.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="cardblock" style="min-height: 200px;">
            <div class="body">
              <div class="cardmedia">
                <img class="img-fluid" src="/img/partners/nabodgital.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BoxParticles from "@/components/BoxParticles.vue";
import TextScroller from "@/components/TextScroller.vue";
export default {
  /* eslint-disable */
  name: "Digital",
  components: {TextScroller, BoxParticles},
  mounted() {

  },
  methods : {

  }
}
</script>

<style scoped>

</style>